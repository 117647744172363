/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'DINPro';
    src: url('DINPro-Black.woff2') format('woff2'),
		url('DINPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'DINPro';
    src: url('DINPro-Bold.woff2') format('woff2'),
		url('DINPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'DINPro';
    src: url('DINPro-Light.woff2') format('woff2'),
		url('DINPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'DINPro';
    src: url('DINPro-Medium.woff2') format('woff2'),
		url('DINPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'DINPro';
    src: url('DINPro-Regular.woff2') format('woff2'),
		url('DINPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}