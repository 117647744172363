@import 'res/DINPro/stylesheet.css';
@import 'res/BlenderPro/stylesheet.css';

html,
body {
    margin: 0;
    padding: 0;
}

body {
    color: #CFDFF6;
    background: #21252E;
    font-family: 'DINPro';
    font-size: 15px;

    /* Prevent overscrolling in safari. overscrolling creates problem on stichy 
       headers. overscroll pushesh header to sticky mode and flickers ui */
    overflow: hidden;
}

.spring {
    flex: 1;
}

body.stuck .sticky-header-host {
    --padding-top: 129px;
}

.stuck-placeholder {
    display: none;
    height: 129px;
}

body.stuck .stuck-placeholder {
    display: block;
    height: 129px;
}

input,
button {
    font-family: 'DINPro';
}

.hy-input {
    .label {
        font-size: 15px;
        color: #525969; // #909296;
        margin-left: 20px;
        margin-bottom: 7px;
        margin-top: 25px;
    }

    input {
        background-color: #0E1017;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 4px;
        line-height: 46px;
        color: #CFDFF6;
        font-size: 16px;
        padding: 0 20px;
        box-sizing: border-box;
    }


    input:hover {
        background: #13151F;
    }

    input:focus {
        outline: 0;
    }

    &.error {
        input {
            border: 1px solid #E31B54;
        }
    }
}

.hy-btn {
    line-height: 60px;
    background: #5EAA60;
    border-radius: 5px;
    font-size: 15px;
    letter-spacing: 1px;
    color: #191C25;
    text-align: center;
    text-decoration: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-weight: 500;

    transition: background-color 250ms, color 250ms;

    &:hover {
        background: #6DC66F;
    }

    &:disabled,
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.secondary {
        background: #393F4B;
        color: #CFDFF6;

        &:hover {
            background: #464D5C;
        }

        &.disabled {
            background: #272b35;
            color: #4c5360;
            opacity: 1;
        }
    }

    &.dark {
        background: #161922;
        color: #CFDFF6;
        letter-spacing: normal;
        font-size: 18px;

        &:hover {
            background: #464D5C;
        }
    }

    &.outline,
    &.no-outline {
        background: none;
        border: 1px solid #393F4B;
        line-height: 58px;
        color: #CFDFF6;

        &:hover {
            background: #464D5C;
        }
    }

    &.no-outline {
        border-color: transparent;
    }

    &.small {
        line-height: 28px;
        border-radius: 14px;
        font-size: 12px;
    }

    &.mid {
        line-height: 46px;
    }

    .ico-24 {
        width: 24px;
        height: 24px;
        background: url(res/icons/filter-24.svg) no-repeat center center;
        margin-right: 12px;

        &.right {
            margin-right: 0;
            margin-left: 12px;
        }
    }
}

/***** hy-*-e are input controls used by some sidebars ************************/

/** Title of the input control. */
.hy-e-title {
    font-size: 12px;
    margin-bottom: 11px;
    color: #525969;
}


.hy-input-e {
    display: flex;
    background: #151823;
    border-radius: 4px;
    border: 1px solid transparent;

    input[type=text] {
        flex: 1;
        border: 0;
        outline: 0;
        background: none;
        color:  #CFDFF6;
        padding: 10px;
        font-family: 'DINPro';
        font-size: 18px;
        
    }

    &.readonly,
    &.disabled {
        background: none;
        border: 1px solid #21252E;
    }


}

.show-input-errors hy-input-group.ng-invalid .hy-input-e {
    border-color: #E31B54;
}

.hy-select-e {
    background: #151823;
    border-radius: 4px;
    position: relative;

    &:after {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #CFDFF6;
        position: absolute;
        top: 45%;
        right: 15px;
        content: "";
        z-index: 98;
    }

    select {
        appearance: none;
        width: 100%;
        position: relative;
        z-index: 99;
        border: 1px solid transparent;
        border-radius: 4px;
        outline: 0;
        background: none;
        color:  #CFDFF6;
        padding: 10px;
        font-family: 'DINPro';
        font-size: 18px;

        &:disabled {
            cursor: not-allowed;
        }
    }

    option {
        background: #151823;
        color: #CFDFF6;
        padding: 11px;
    }
}

.show-input-errors .hy-select-e select.ng-invalid {
    border-color: #E31B54;
}

.input-k-errors > div {
    color: #E31B54;
    margin-top: 8px;
}

/***** hy-check ***************************************************************/

.hy-check {
    line-height: 40px;
    width: 40px;
    background: #151823;
    cursor: pointer;

    &:after {
        content: "";
        width: 2px;
        height: 40px;
        transition: width 250ms;
        display: block;
        background: #525969;
    }
}

.hy-check-row {
    color: #717A89;
    cursor: pointer;

    &:not(.active):hover {
        color: #CFDFF6;

        .hy-check:after {
            width: 10px;
        }
    }

    &.active {
        color: #CFDFF6;
    }
}


.active .hy-check {
    &:after {
        background: #4ca256 url(res/icons/check-12.svg) center center no-repeat;
        width: 100%;
    }
}

/***** hy-settings-field ******************************************************/

.hy-settings-field {
    display: flex;

    .left {
        flex: 1;
    }

    .label {
        font-size: 12px;
        letter-spacing: 0.03em;
        color: #525969;
        margin-bottom: 8px;
    }

    .value {
        font-size: 18px;
        color: #CFDFF6;
        padding-right: 10px;
    }

    .edit {
        display: flex;
        gap: 6px;
        align-items: center;
        height: 48px;
        padding: 0 23px;
        border: 1px solid #151823;
        border-radius: 24px;
        font-size: 12px;
        color: #4D5466;
        cursor: pointer;

        transition: border-color 250ms, color 250ms;

        &:hover {
            border-color: #CFDFF6;
            color: #CFDFF6;
        }
    }

    .ico {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(res/icons/pencil-16.svg) no-repeat;
    }

    &.first {
        margin-top: 53px;
    }

    &.no-edit .value {
        color: #303540;
    }
}

/***** double-charts-page *****************************************************/


.double-charts-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .charts {
        flex: 1;
        display: flex;
        padding: 30px 0;
        margin: 0 30px;
    }

    hy-date-selection-header {
        padding: 0 30px;
    }

    hy-line-chart-block {
        flex: 1;
    }

    hy-line-chart-block:first-child {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 1280px) {
    .double-charts-page {
        height: auto;

        .charts {
            flex-direction: column;
        }

        hy-line-chart-block {
            min-height: 710px;
        }

        hy-line-chart-block:first-child {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
}

/***** Icons ******************************************************************/

.ico-collapse {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(res/icons/collapse.svg) no-repeat;

}

.device-0,
.device-1,
.device-2,
.device-3,
.device-4,
.device-5,
.device-6,
.device-7,
.device-8,
.device-battery,
.device-compressor,
.device-cooler,
.device-cryo_tanks,
.device-el_grid,
.device-electrolyser,
.device-fuel_cell,
.device-gas_cylinder,
.device-hydrogen_pipeline,
.device-hystor,
.device-industry_process,
.device-solar_panels,
.device-wind_turbines {
    display: inline-block;
    width: 64px;
    height: 64px;
    background: no-repeat;
}

.device-11                { background-image: url(res/icons/devices/tidal_turbines_icon_big.svg   ) }
.device-3,
.device-battery           { background-image: url(res/icons/devices/battery_icon_big.svg          ) }
.device-5,
.device-compressor        { background-image: url(res/icons/devices/compressor_icon_big.svg       ) }
.device-cooler            { background-image: url(res/icons/devices/cooler_icon_big.svg           ) }
.device-cryo_tanks        { background-image: url(res/icons/devices/cryo_tanks_icon_big.svg       ) }
.device-1,
.device-el_grid           { background-image: url(res/icons/devices/el_grid_icon_big.svg          ) }
.device-4,
.device-electrolyser      { background-image: url(res/icons/devices/electrolyser_icon_big.svg     ) }
.device-8,
.device-fuel_cell         { background-image: url(res/icons/devices/fuel_cell_icon_big.svg        ) }
.device-6,
.device-9,
.device-gas_cylinder      { background-image: url(res/icons/devices/gas_cylinder_icon_big.svg     ) }
.device-hydrogen_pipeline { background-image: url(res/icons/devices/hydrogen_pipeline_icon_big.svg) }
.device-7,
.device-hystor            { background-image: url(res/icons/devices/hystor_icon_big.svg           ) }
.device-2,
.device-industry_process  { background-image: url(res/icons/devices/industry_process_icon_big.svg ) }
.device-solar_panels      { background-image: url(res/icons/devices/solar_panels_icon_big.svg     ) }
.device-0,
.device-wind_turbines     { background-image: url(res/icons/devices/wind_turbines_icon_big.svg    ) }


/*****  Tooltip ***************************************************************/

.tooltips-enabled {

    .htooltip,
    .itooltip {
        position: relative;

        &::after {
            visibility: hidden;
            position: absolute;
            z-index: 200;
            right: 0;
            top: -31px;
            font-size: 15px;
            content: attr(data-tooltip);
            background: #2E3340;
            color: #CFDFF6;
            padding: 4px 8px 6px;
            font-family: "DINPro";
            text-decoration: none;
            border-radius: 5px;
            white-space: nowrap;
            opacity: 0;

            transition: opacity 250ms;
        }

        &.offset-10::after {
            right: -10px;
        }

        &.offset-t5::before {
            top: -8px;
        }
        &.offset-t5::after {
            top: -36px;
        }


        &.center {
            &::after {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.right {
            &::after {
                right: auto;
                left: 0;
            }
        }

        &::before {
            content: "";
            position: absolute;
            visibility: hidden;
            opacity: 0;
            top: -3px;
            left: 50%;
            margin-left: -8px;
            border-top: 8px solid #2E3340;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;

            transition: opacity 250ms;
        }

        &:hover{
            &::after,
            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .itooltip {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background: url(res/info_tooltip.svg) center center;
    }

}

/***** ESC button *************************************************************/

.esc {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    line-height: 36px;
    border-radius: 18px;
    padding: 0 19px;
    background: #151823;
    color: rgba(134, 145, 170, 0.5);
    cursor: pointer;
    transition: color 250ms, background-color 250ms;

    svg {
        width: 16px;
        height: 16px;
        color: #CFDFF6;
    }

    &:hover  {
        background: #393C46;
        color: rgba(207, 223, 246, 0.5);
    }
}

/***** Animations *************************************************************/

.hy-fadein {
    animation: hy-fadein 250ms;
}

@keyframes hy-fadein {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}


/***** Style customization of ngx-daterangepicker-material ********************/


body .md-drppicker { /* added body to make it more specific then default styles */
    background: #21252D;
    border-radius: 8px;
    font-family: DINPro;
    font-size: 15px;
    padding: 20px;
    width: 655px !important;
    margin-top: 4px;
    transform: none !important;
    visibility: hidden;
    opacity: 0;

    /** x axis position correction to fit the end of input box on notifications filter. */
    margin-left: 4px;

    &.shown {
        /* Originaly picker uses transform to display itself (scale(0) to scale(1).
           This cause the problem to display the overly as transform introduces
           new cordinate system origin for position: fixed, and overlay cannot
           cover whole screen. */
        transform: none;
        visibility: visible;
        opacity: 1;
        transition: opacity 250ms;
    }

    /* Overlay behind the picker popup */
    &:before {
        position: fixed;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    .calendar-table {
        background: none;
        border: none;
    }

    .table-condensed {
        border-collapse: collapse;
    }

    .ranges {
        margin-right: 20px;
        width: 120px;

        ul li button {
            color: #CFDFF6;
            padding: 0;
            line-height: 36px;
            border: 2px solid transparent;
            border-radius: 4px;
            padding: 0 10px;
            margin-bottom: 4px;
            font-size: 15px;

            &.active {
                background-color: #4CA256;
                color: #0C0E17;
                font-weight: bold;
            }

            &:hover {
                border: 2px solid #4CA256;
            }
        }
        ul li:hover {
            background: none
        }
    }

    th {
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #6E7786;
        font-weight: normal;
    }

    th.month {
        font-size: 18px;
    }

    .week-days th:nth-child(7) {
        color: #EF0056;
    }

    th.available.prev,
    th.available.next {
        background: url(res/icons/arrow-right-12.svg) center center no-repeat;
        opacity: 1;
        transform: none;
        border: 2px solid transparent;
        border-radius: 50px;
        height: 32px;

        &:hover {
            border-color: #4CA256;
        }
    }

    th.available.next:hover {
        transform: none;
    }

    th.available.prev {
        transform: rotate(180deg);
    }


    td {
        border: 0;
        border-bottom: 4px solid transparent;
        opacity: 1;
        transition: none;

        span {
            border: 2px solid transparent;
            display: block;
            line-height: 30px;
            font-size: 16px;
            color: #CFDFF6;
        }

        &.off {
            visibility: hidden;

        }

        &.start-date,
        &.end-date,
        &.in-range {
            background: none;

            span {
                color: #0C0E17;
                font-weight: bold;
                background: #4CA256;
            }
        }

        &.in-range span {
            border-radius: 0 !important;
        }

        &.start-date span {
            border-radius: 50px 0 0 50px !important;
        }

        &.end-date span {
            border-radius: 0 50px 50px 0 !important;
        }

        &.start-date.end-date span {
            border-radius: 50px !important;
        }

        &.available:hover {
            background: none;

            span {
                border-color: #4CA256;
                border-radius: 50px;
            }
        }
    }

    .btn {
        line-height: 40px;
        background: #5EAA60;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #191C25;
        text-align: center;
        border: 0;
        width: 80px;
        cursor: pointer;

        &.clear svg {
            display: none;
        }

        &:hover {
            background-color: #6DC66F;
        }

        &.btn-default {
            background-color: #393F4B !important;
            color: #CFDFF6;
            margin-right: 8px;

            &:hover {
                background-color: #464D5C !important;
            }
        }
    }
}



.featured-ico {
    padding: 14px;
    border-radius: 999px;
    background: #151823;
    color: #CFDFF6;
    width: 56px;
    height: 56px;
    box-sizing: border-box;

    svg {
        display: block;
        width: 28px;
        height: 28px;
    }
}

