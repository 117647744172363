@font-face {
    font-family: 'Blender Pro';
    src: url('BlenderPro-Medium.woff2') format('woff2'),
        url('BlenderPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Blender Pro';
    src: url('BlenderPro-Thin.woff2') format('woff2'),
        url('BlenderPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Blender Pro';
    src: url('BlenderPro-Bold.woff2') format('woff2'),
        url('BlenderPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

